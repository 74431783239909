<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <h3>Lojas</h3>
      </div>
    </div>

    <div class="grid" v-if="logisticaAtual == null">
      <div class="col-12">
        <div class="border-pink-500 surface-overlay border-2 border-round font-bold text-2xl p-3 flex align-items-center justify-content-center">
            Selecione a Empresa de Logística primeiro.
        </div>
      </div>
    </div>
    
    <div class="grid" v-else>

      <div class="col-12">
        <strong>API Empresa de Logística:</strong> {{ logisticaAtual.token }}
      </div>

      <div class="col-12 md:col-7" id="usuarios">
        <DataTable :value="usuarios" :lazy="true" :loading="loading">
          <Column class="col-nome" field="nome" header="Nome"></Column>
          <Column class="col-email" field="email" header="E-Mail"></Column>
          <Column class="col-status" field="statusEmpresa" header="Status"></Column>

          <Column class="col-actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning p-mr-2"
                @click="alterarForm(slotProps.data)"
              />
            </template>
          </Column>

          <template #empty> Nenhum Usuário encontrado. </template>
        </DataTable>
      </div>

      <div class="col-12 md:col-5">

        <div class="card p-fluid">
          <h5 v-if="usuario == null">Nova Loja:</h5>
          <h5 v-else>Alteração de Loja: </h5>

          <div class="grid">

            <div class="p-field col-12" v-if="usuario">
              <strong>{{ usuario.nome }}</strong><br><br><strong>API:</strong> {{ usuario.token }}
            </div>

            <div class="p-field col-12">
              <label for="senhaRelatorios">Senha dos relatórios</label>
              <InputText id="senhaRelatorios" type="text" v-model="senhaRelatorios" />
            </div>

            <div class="p-field col-12">
              <label for="lancamentoBloqueado">Lançamento Manual</label>
              <div><InputSwitch v-model="lancamentoBloqueado" /></div>
            </div>

            <div class="p-field col-12" v-show="usuarioLogado != null && usuarioLogado.permicao == 'Admin'">
              <label for="autoComplete">AutoComplete</label>
              <div><Dropdown v-model="autoComplete" :options='[{nome: "Google"},{nome: "Here"}]' optionLabel="nome" optionValue="nome" placeholder="AutoComplete" /></div>
            </div>

            <div class="col-12" v-if="usuario == null">
              <Button
                label="Gravar Nova Loja"
                icon="pi pi-check"
                class="p-button-success"
                @click="criar"
              ></Button>
            </div>

            <div class="col-12" v-else>
              <div class="grid">
                <div class="col-6">
                  <Button
                    label="Alterar"
                    icon="pi pi-check"
                    class="p-button-success"
                    @click="alterar"
                  ></Button>
                </div>
                <div class="col-6">
                  <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-warning"
                    @click="cancelarAlteracao"
                  ></Button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <Toast position="top-right" />

  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export default {
  data() {
    return {

      // Dados cadastrados
      usuarios: [],
      logisticaAtual: null,
      usuarioLogado: null,

      // Controle de Apresentação
      cadastro: true,
      loading: false,

      // Dado selecionado
      usuario: null,

      // Campos do Formulário
      senhaRelatorios: "",
      lancamentoBloqueado: true,
      autoComplete: "Here",

    };
  },

  mounted() {

    if(this.$root.logisticaAtual != null) {
      this.logisticaAtual = this.$utils.getStdObject(this.$root.logisticaAtual);
      this.usuarioLogado = this.$root.user;
    }

  },

  watch: {

    '$root.logisticaAtual': {
      handler: function(val) {
        if(this.logisticaAtual == null || val.id != this.logisticaAtual.id)
          this.logisticaAtual = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    '$root.user': {
      handler: function(val) {
        this.usuarioLogado = val;
      },
      deep: true,
      immediate: true
    },

    logisticaAtual: function(val) {
      const self = this;
      this.logisticaAtual.token = Buffer.from(val.id, 'ascii').toString('base64');
      this.usuarios = [];
      if(val != null) {
        this.loading = true;

        firebase.database().ref("Usuarios").orderByChild("empresaLogistica/id").equalTo(val.id)
        .once("value", (snapshot) => {

          if (snapshot.exists()) {

            for(const u of Object.values(snapshot.val())) {
            
              if(u.permicao == "Usuario") {
                u.token = Buffer.from(u.id, 'ascii').toString('base64');
                self.usuarios.push(u);
              }
            }

          }

          self.loading = false;

        });

      }
    },

    usuario: function (val) {

      if (val == null) {
        this.limparFormulario();
      } else {
        //Popula a tela de Alteração
        this.senhaRelatorios = val.senhaRelatorios;
        this.lancamentoBloqueado = !val.lancamentoBloqueado;
        this.autoComplete = val.autoComplete;
      }

    },
  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    limparFormulario() {
      //Limpa as variáveis
      this.senhaRelatorios = "";
      this.autoComplete = "Here";
      this.lancamentoBloqueado = true;

      //limpa o objeto
      this.usuario = null;    
    },

    // // // AÇÕES DO USUÁRIO // // //

    cancelarAlteracao() {
      this.limparFormulario();
    },

    criar() {
      alert("Função indisponível!");
    },

    alterar() {
      const self = this;

      if(typeof self.autoComplete != "string" || self.autoComplete == "")
        self.autoComplete = "Here";

      //TODO Validação de dados, principalmente undefineds
      self.usuario.lancamentoBloqueado = !self.lancamentoBloqueado;
      self.usuario.senhaRelatorios = self.senhaRelatorios;
      self.usuario.autoComplete = self.autoComplete;

      firebase.database().ref("Usuarios").child(self.usuario.id).update({
        "lancamentoBloqueado": !self.lancamentoBloqueado,
        "senhaRelatorios": self.senhaRelatorios,
        "autoComplete": self.autoComplete,
      }).then(function() {

        for (const k in self.usuarios) {
          if (self.usuarios[k].id == self.usuario.id) {
            self.usuarios[k] = self.usuario;
            break;
          }
        }

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Loja alterada com sucesso!",
          life: 3000,
        });

        self.cancelarAlteracao();

      }).catch(function(error) {

        if (error) {
          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: error.message,
            life: 5000,
          });
        }

      });

    },

    alterarForm(obj) {
      this.usuario = obj;
      window.scrollTo(0,0);
    },
    
  },
};
</script>

<style>
  #usuarios tr td:nth-child(4), #usuarios tr th:nth-child(4) {
    width: 65px;
  }

  #usuarios tr td:nth-child(3), #usuarios tr th:nth-child(3) {
    width: 110px;
  }
</style>